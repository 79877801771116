import React from 'react';
import { Link, navigate } from 'gatsby';

import Layout from '../../../components/layout/Layout';

import NewsletterSub from '../../../components/NewsletterSub';

import { ArrowLeftIcon, CheckCircleIcon } from '@heroicons/react/solid'



const siteVerOpts = [

    {
      label: 'Internacional',
      value: '',


    },

    {
      label: 'Ecuador',
      value: '/',


    },
  ];

  const langOpts = [


    {
      label: 'ES',
      value: '',

    },


    {
      label: 'EN',
      value: `/export/en/thank-you`,

    },


  ];


const seo={
    title: "Gracias por su contacto.",
    desc:  "",
    image: "",
    pathname: "/export/en/gracias/",
  }

 export default function Named ({location}) {


   return(
    <Layout location={location} crumbLabel="Gracias"
    siteVerOpts={siteVerOpts} langOpts={langOpts}
    title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>

        <section id="" className="p-10">

          <div className="relative bg-green-100 border-t border-b border-green-500 text-green-600 px-4 py-2" role="alert">
            <div className="absolute top-2 left-4 w-6">
              <CheckCircleIcon className="" />
            </div>
            <p className="font-bold ml-8">Gracias por su contacto!</p>
            <p className="text-sm mt-4 text-primary-default">Hemos recibido sus datos correctamente y uno de nuestros asesores se pondra en contacto con usted muy pronto para atender su requerimiento.</p>
          </div>
          <div className="mt-2 ml-4">
            <p className="text-sm mv-2">Esperamos poder servirlo en un futuro cercano, mientras tanto lo invitamos a seguir  navegando por nuestro sitio.</p>
            <div className="flex flex-row text-sm">
                <Link to="/contactenos/" className="before:content-['_‹-']">Regresar</Link>&nbsp;|&nbsp;

                <Link to="/productos/">Productos de limpieza</Link> &nbsp;|&nbsp;
                <Link to="/quienes-somos/">Conozca mas sobre Unilimpio</Link>
            </div>
          </div>


        </section>

        <NewsletterSub/>

    </Layout>
  )}
